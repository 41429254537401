// out: false

// Include default ABWeb CMS public styles
@import '../../../../../../webroot/assets/src/less/default.less';
// Custom variables
@import (optional) 'variables.less';
// Animations
@import (optional) 'animations.less';

header {
    #logo {
        position: fixed;
        top: 0px;
        left: 50%;
        height: @header-logo-height;
        width: @header-logo-width;
        z-index: 2000;

        div.container {
            position: relative;
            width: @header-logo-width;

            h1 {
                position: absolute;
                left: 0;
                top: 6px;
                z-index: 102;
                height: @header-logo-height;
                width: @header-logo-width;
            }
        }

        @media (min-width: @screen-lg-min) {
            margin-left: (((@screen-lg-min - @grid-gutter-size) / 2) * -1);
        }

        @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
            margin-left: (((@screen-md-min - @grid-gutter-size) / 2) * -1);
        }

        @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
            margin-left: (((@screen-sm-min - @grid-gutter-size) / 2) * -1);
        }

        @media (max-width: @screen-xs-max) {
            left: 5px;
            width: 220px;

            div.container {
                width: 220px;

                h1 {
                    width: 220px;
                }
            }
        }
    }

    #main-nav {
        background: @main-back-colour;

        #main-nav-bar {
            position: relative;
            z-index: 2001;
            margin-top: 28px;
            height: 44px;
            background: @navbar-back-colour;

            nav.navbar {
                padding-left: @header-logo-width + 25px;

                .navbar-brand {
                    height: 44px;
                    line-height: 44px;

                    @media (max-width: @mobile-menu-switchover) {
                        display: none;
                    }
                }

                .navbar-nav {
                    height: 44px;

                    li a {
                        line-height: 44px;
                    }
                }

                .navbar-toggle {
                    top: 0;
                    height: 44px;
                    line-height: 44px;
                }

                @media (max-width: @mobile-menu-switchover) {
                    .navbar-collapse {
                        left: 0;
                        width: 97%;
                    }
                }
            }
        }
    }

    #jumbo {
        height: 260px;

        .jumbo-inner {
            height: 260px;
            background: #f4f4f4;

            .container {
                height: 260px;
                position: relative;
                padding: 0;

                .jumbo-image {
                    width: 100%;
                    height: 260px;
                    overflow: hidden;
                    position: relative;
                    opacity: 1;

                    .full-image,
                    .preview-image {
                        position: absolute;
                        top: 0;
                        left: 0;
                    }

                    .full-image {
                        -webkit-animation: jumboFadeIn 750ms;
                        animation: jumboFadeIn 750ms;
                    }
                }

                .jumbo-text {
                    position: absolute;
                    padding: 15px 15px;
                    bottom: 20px;
                    left: 0px;
                    width: 40%;
                    background: rgba(0, 0, 0, 0.7);
                    color: #fff;

                    h2 {
                        color: #fff;
                        padding: 0;
                        margin: 0;
                        font-size: 1.5em;
                    }
                }
            }
        }
    }
}

.page-header {
    border-bottom: 3px solid #FCD703;

    &:first-child {
        margin-top: 0;
    }

    small {
        font-size: 1em;
        color: #00529B;
    }
}

.page-header:not(.text-right) {
    margin-bottom: 0 !important;
    border-bottom: 0 !important;
    color: #243776 !important;
}

hr {
    border-top: 3px solid #FCD703;
}

.home-news-table {
    border: none !important;

    td {
        border: 15px solid @main-back-colour !important;
        background: #F4F4F4;
        padding: 15px !important;
    }
}

.brands {
    margin-bottom: 40px;

    & > div > div {
        margin-bottom: 10px;
        height: 44px;

        img {
            margin: 0 0 0 auto;
        }
    }
}

.modelsfinder {
    margin-bottom: 40px;

    & > div > div {
        img {
            margin: 0 0 0 auto;
        }
    }

    p.locator-label {
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        margin-top: 7px;
    }
}

.parts,
.our-brands {
    margin-top: 40px;

    .part {
        height: 240px;
        line-height: 240px;
    }

    .brand {
        height: 120px;
        line-height: 120px;
    }
}

.brands, .modelsfinder {
    .slick-slide {
        height: 75px;
        padding: 15px;
        img {
            display: block !important;
            margin-left: auto !important;
            margin-right: auto !important;
        }
    }
    .slick-next, .slick-prev {
        display: none !important;
    }
}

.container.main-content {
    a:not(.btn) {
        text-decoration: underline !important;
    }
}

footer .social {
    img {
        opacity: 0.5;
    }

    &:hover img {
        opacity: 0.9;
    }
}

@import 'slick.less';
@import 'slick-theme.less';
@import 'lightbox.less';
@import 'blog.less';
