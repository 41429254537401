#christmasHours>table, #christmasHours>table table {
    width: 100%;
}

#christmasHours>table table td {
    padding: 4px 6px;
}

#christmasHours>table table tr:nth-of-type(2n+1) td {
    background: rgba(252, 215, 3, .1);
}

#christmasHours {
    background: #FCD703;
    margin-bottom: 24px;

    h3 {
        color: #243776;
        font-weight: bold;
    }
}

@media screen and (max-width: 500px) {
    #christmasHours > table td.col-1, #christmasHours > table td.col-2 {
        width: 100%;
        display: block;
    }
}
