// main: style.less

/*
ABWEB CMS STYLE CONFIGURATION

This LESS file contains many variables used to make deploying styles to a new installation of ABWeb CMS easy. It's best to use this file to cover as much
group style-wise as possible, before editing the style.less file for additional changes and extra elements not covered in ABWeb CMS
*/

/*--------------------- MAIN */

@main-back-colour:                  #ffffff;                // Main background colour
@main-fore-colour:                  #444444;                // Main foreground (text) colour
@canvas-colour:                     #4f718f;                // Some effects may resize the viewport. If the void behind the page is visible,
                                                          // this colour is used to colour that void.

/*--------------------- TYPOGRAPHY */

@use-google-fonts:                  true;                   // If true, will use an external Google Fonts stylesheet to provide the fonts below

@content-font:                      'Arial';
@content-typography:                sans-serif;
@content-normal-weight:             400;
@content-bold-weight:               700;
@content-include-italic-font:       false;                  // Set to true to load the italic and bold-italic version of this font as well
@content-size:                      14px;                   // This represents the default size of text on the page. All other font sizes are derived from this.
@content-line-height:               (@content-size * 1.66);

@heading-font:                      'Arimo';
@heading-typography:                sans-serif;
@heading-weight:                    400;
@heading-include-italic-font:       false;                  // Set to true to load the italic and bold-italic version of this font as well
@heading-colour:                    @main-fore-colour;                // Used on H1, H3 and H5 tags
@heading-alt-colour:                @main-fore-colour;                // Used on H2, H4 and H6 tags

@include-additional-font:           false;                  // If you wish to include a third font, set this to true and set the values below
@additional-font:                   'Roboto';
@additional-typography:             sans-serif;
@additional-normal-weight:          400;
@additional-bold-weight:            700;
@additional-include-italic-font:    false;                  // Set to true to load the italic and bold-italic version of this font as well

@include-monospace-font:            false;
@monospace-font:                    'Droid Sans Mono';
@monospace-typography:              monospace;
@monospace-weight:                  400;

/*--------------------- GLOBAL COLOURS AND STYLES */

// Colours used mostly for buttons, alerts and highlights
@colour-primary:                    #243776;
@colour-success:                    #4D9C4D;
@colour-info:                       #68B3D3;
@colour-warning:                    #f0ad4e;
@colour-danger:                     #d9534f;

@border-radius-size:                0px;                    // Defines the normal border radius size - set to 0px to use a flat look

@grid-gutter-size:                  30px;                   // Determines the padding between grid columns.

/*--------------------- HEADER */

@header-back-colour:                #ffffff;
@header-fore-colour:                #000000;
@header-height:                     260px;
@header-bottom-spacing:             50px;

@header-use-logo:                   true;                   // When true, the H1 tag in the header will be replaced with a site logo that can be specified below
@header-logo-image:                 'logo.png';   // Image file to use as logo. Must reside in the /app/webroot/img folder or one of its subfolders
@header-logo-position:              'left';                 // Set the alignment of the logo or site name (use left, right or centre)
@header-logo-width:                 307px;
@header-logo-height:                91px;
@header-vertical-gutter:            0px;                   // Provides a padding buffer at the top and bottom of the header to prevent the logo taking up all the vertical space

@header-use-background-image:       false;                   // When true, the background of the header will be an image resized to fit within the constrains of the header
@header-background-image:           'included/lightswirl.jpg';      // Image file to use as background. Must reside in the /app/webroot/img folder or one of its subfolders

/*--------------------- HOME PAGE */

@home-page-jumbo:                   false;                   // If true, will resize the header area to be large on the front page for more visual impact
@home-page-header-height:           400px;
@home-page-header-logo-width:       500px;
@home-page-header-logo-height:      150px;
@home-page-header-vertical-gutter:  30px;                   // Provides a padding buffer at the top and bottom of the header to prevent the logo taking up all the vertical space

/*--------------------- NAVIGATION */

@navbar-position:                   'fixed-top';                  // Values: 'fixed-top' (default), 'fixed-bottom', 'top', bottom
@navbar-back-colour:                #FCD703;
@navbar-fore-colour:                #ffffff;
@navbar-height:                     72px;
@navbar-item-padding:               12px;
@navbar-font-size:                  1.15em;
@show-brand:                        true;                   // Brand is usually used to show a "Home" page link, but could also contain the site name

@mobile-menu-switchover:            992px;                 // Maximum screen width when the menu becomes the mobile-friendly version. Default is 768px. For 'sm' - use 992px. For 'md' - use 1200px. For 'lg' - use 9999px

@navbar-hover-back-colour:          #243776;
@navbar-hover-fore-colour:          #fff;

@navbar-active-back-colour:         #243776;
@navbar-active-fore-colour:         contrast(@navbar-active-back-colour);

@navbar-disabled-back-colour:       #243776;
@navbar-disabled-fore-colour:       darken(@navbar-fore-colour, 15%);

// Dropdown menus
@dropdown-back-colour:              lighten(#243776, 15%);
@dropdown-fore-colour:              @navbar-fore-colour;
@dropdown-border-colour:            darken(@dropdown-back-colour, 10%);
@dropdown-divider-colour:           lighten(@dropdown-back-colour, 10%);
@dropdown-disabled-colour:          darken(@navbar-fore-colour, 15%);

@dropdown-hover-back-colour:        @navbar-hover-back-colour;
@dropdown-hover-fore-colour:        @navbar-hover-fore-colour;

@dropdown-active-back-colour:       @navbar-active-back-colour;
@dropdown-active-fore-colour:       @navbar-active-fore-colour;

// Submenu found on page
@submenu-back-colour:               #f4f4f4;
@submenu-fore-colour:               #333333;

@submenu-hover-back-colour:         contrast(@submenu-back-colour, lighten(@submenu-back-colour, 15%), darken(@submenu-back-colour, 15%));
@submenu-hover-fore-colour:         contrast(@submenu-hover-back-colour);

@submenu-active-back-colour:        #541C51;
@submenu-active-fore-colour:        contrast(@submenu-active-back-colour);

// Tabs and pills
@tab-back-colour:                   contrast(@main-back-colour, lighten(@main-back-colour, 1%), darken(@main-back-colour, 1%));
@tab-fore-colour:                   contrast(@main-back-colour, #666, #aaa);
@tab-padding:                       10px 15px;
@tab-border-colour:                 contrast(@main-back-colour, lighten(@main-back-colour, 12%), darken(@main-back-colour, 12%));

/*--------------------- FOOTER */

@sticky-footer:                     true;                   // If true, the footer will stick to the bottom of the page

@footer-back-colour:                #FCD703;
@footer-fore-colour:                rgba(0, 0, 0, 0.5);
@footer-height:                     60px;

/*--------------------- LINKS */

@link-colour:                       #243776;
@link-hover-colour:                 #8e680a;
@link-hover-type:                   none;

/*--------------------- FORMS */

@form-fore-colour:                  @main-fore-colour;
@form-back-colour:                  @main-back-colour;
@form-border-colour:                darken(@form-back-colour, 25%);

@form-focus-fore-colour:            @form-fore-colour;
@form-focus-back-colour:            @form-back-colour;
@form-focus-border-colour:          #66afe9;

@select2-hover-fore-colour:         #fff;
@select2-hover-back-colour:         #5897fb;

@select2-selected-fore-colour:      @form-fore-colour;
@select2-selected-back-colour:      darken(@form-back-colour, 5%);
@select2-selected-border-colour:    #57bf2d;

@froala-ui-colour:                  @abweb-grey;
@froala-ui-action-color:            @abweb-orange;
@froala-separator-colour:           lighten(@abweb-grey, 10%);

@froala-button-fore-colour:         #e7e7e7;
@froala-button-back-colour:         @froala-ui-colour;
@froala-button-hover-fore-colour:   #fff;
@froala-button-hover-back-colour:   lighten(@froala-ui-colour, 10%);
@froala-button-active-fore-colour:  @abweb-orange;
@froala-button-active-back-colour:  darken(@froala-ui-colour, 10%);
@froala-button-disabled-fore-colour:  #777;
@froala-button-disabled-back-colour:  @froala-ui-colour;

@froala-widget-back-colour:         #fff;
@froala-widget-fore-colour:         @main-fore-colour;
@froala-widget-border-colour:       @froala-ui-colour;

@froala-widget-hover-fore-colour:  @main-fore-colour;
@froala-widget-hover-back-colour:  #66afe9;
@froala-widget-active-fore-colour:  @main-fore-colour;
@froala-widget-active-back-colour:  @abweb-orange;

/*--------------------- TABLES */

@table-back-colour:                 @main-back-colour;
@table-back-alt-colour:             darken(@table-back-colour, 2%);

@table-hover-back-colour:           screen(@link-colour, darken(@table-back-colour, 6%));

@table-border-colour:               darken(@table-back-colour, 15%);

@table-padding:                     8px;

/*--------------------- TOOLTIPS */

@tooltip-back-colour:               #2D133A;
@tooltip-fore-colour:               #fff;
@tooltip-opacity:                   0.95;

/*--------------------- ABWEB CMS COLOURS */

@abweb-orange:                      #E5A220;
@abweb-grey:                        #444444;
@abweb-light-grey:                  #C7CBCD;

/*--------------- ABWEB CMS INTERNALS */

@abwebmenu-back-colour:             #282828;
@abwebmenu-fore-colour:             #dddddd;
@abwebmenu-active-back-colour:      #444444;
@abwebmenu-active-fore-colour:      #ffffff;

@abweb-table-border:                #d5d5d5;
@abweb-table-th-back:               lighten(@colour-primary, 52%);
@abweb-table-hover:                 #EDF7ED;

/*--------------------- BOOTSTRAP */

// Features
@bootstrap-include-jumbotron:       false;
@bootstrap-include-thumbnails:      false;
@bootstrap-include-media:           false;
@bootstrap-include-responsive-embed: false;

/*--------------------- UTILITIES */

@debranded:                         false;                  // If true, any styles and logos that make ABWeb CMS branded to ABWeb will be removed
