// main: style.less

.article-grid {
    .article-grid-container {
        padding: 10px;
        margin: 0px (((@grid-gutter-size - 5px) / 2) * -1);
        background-color: @well-bg;
        border: 1px solid @well-border;
        min-height: 350px;
        position: relative;
        display: flex;
        flex-direction: column;

        .article-grid-image {
            height: 85px;
            flex-shrink: 0;
            flex-grow: 0;

            img {
                border: 1px solid @well-border;
            }
        }

        .article-grid-details {
            flex-grow: 1;
            margin-top: 10px;
            padding-bottom: 30px;

            h3 {
                font-size: 1.4em;
                margin: 0;

                a:not(.btn) {
                    text-decoration: none !important;
                }
            }

            .article-date {
                font-size: 0.85em;
                color: rgba(0, 0, 0, 0.7);
                border-bottom: 3px solid #FCD703;
                padding-bottom: 8px;
                margin-bottom: 8px;
            }
        }

        .article-grid-actions {
            position: absolute;
            bottom: 10px;
            left: 10px;
            width: 90%;
            height: 30px;

            .btn {
                width: 100%;

                .btn-label {
                    background: none;
                }
            }
        }
    }
}

.article-thumbnail {
    margin-left: 20px;
    margin-bottom: 20px;
}

.article-byline {
    clear: both;
    background-color: @well-bg;
    border: 1px solid @well-border;
    padding: 5px 10px;
}
